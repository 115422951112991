/* eslint-disable */
import { gql } from 'nuxt-graphql-request'
import { Alternates, Asset, PageHero, BlogPostCard, EventsPreview, SwisstainableDetailpagePreview } from './fragments'
import {
  PagesBlueprint,
  TeamBlueprint,
  TeamMemberBlueprint,
  BlogPostBlueprint,
  EventsBlueprint,
  OfferBlueprint,
  PartnerBlueprint,
  SwisstainableHubDetailpageBlueprint,
} from './fragments-blueprint'

// GET Page
export const getPageByUri = gql`
  query getPageByUri($uri: String, $lang: String, $status: String) {
    entry(uri: $uri, site: $lang, filter: {status: $status}) {
      url
      title
      blueprint

      ...Alternates
      ...PagesBlueprint
      ...BlogPostBlueprint
      ...TeamBlueprint
      ...TeamMemberBlueprint
      ...EventsBlueprint
      ...OfferBlueprint
      ...PartnerBlueprint
      ...SwisstainableHubDetailpageBlueprint
    }
  }
  ${Alternates}
  ${Asset}
  ${PageHero}
  ${BlogPostCard}
  ${EventsPreview}
  ${SwisstainableDetailpagePreview}
  # Blueprints
  ${PagesBlueprint}
  ${BlogPostBlueprint}
  ${TeamBlueprint}
  ${TeamMemberBlueprint}
  ${EventsBlueprint}
  ${OfferBlueprint}
  ${PartnerBlueprint}
  ${SwisstainableHubDetailpageBlueprint}
`

// Search entries
export const searchEntries = gql`
  query searchEntries($term: String, $lang: String) {
    entries(filter:
    {
      title: {contains: $term},
      blueprint: {not_in: "level_1"},
      published: { is: true },
      locale: { is: $lang }
    }) {
      data {
        title
        uri
        ...PageHero

        ... on Entry_Pages_Team {
          title
          heros {
            ... on Set_Heros_HeroDetailpage {
              text
            }
          }
        }
      }
    }
  }
  ${Asset}
  ${PageHero}
`

// GET Taxonomy
export const getTags = gql`
  query getTags($taxonomy: [String], $lang: String) {
    terms(taxonomy: $taxonomy, filter: {site: {is: $lang}}) {
      data {
        ... on Term_BlogCategories_BlogCategories {
          slug
          title
        }
        ... on Term_ConsultantCategories_ConsultantCategories {
          slug
          title
          link
        }
        ... on Term_OffersCategories_OffersCategory {
          slug
          title
        }
        ... on Term_SwisstainableHubCategories_SwisstainableHubCategory {
          slug
          title
        }
        ... on Term_SwisstainableHubTopics_SwisstainableHubTopic {
          id
          slug
          title
          topic_area {
            ... on Term_SwisstainableHubTopicAreas_SwisstainableHubTopicArea {
              id
              slug
              title
            }
          }
        }
      }
    }
  }
`
